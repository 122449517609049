import React from 'react'
import { graphql } from 'gatsby'
import { isPast, parseISO, endOfDay } from 'date-fns'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Promos } from '../components/promos'

function isArchived(datetime) {
  const eod = endOfDay(datetime)
  return isPast(eod)
}

export default function AktionenPage({ data }) {
  let promos = data.allStrapiPromo.edges.map(({ node }) => {
    return node
  })

  promos = promos.map((promo) => {
    const datetime = parseISO(promo.datetime)
    return {
      ...promo,
      archived: isArchived(datetime),
    }
  })

  return (
    <Layout>
      <SEO title="Aktionen" />
      <Promos promos={promos} />
    </Layout>
  )
}

export const query = graphql`
  query Promos {
    allStrapiPromo(sort: { fields: datetime, order: ASC }) {
      edges {
        node {
          id
          title
          archived
          datetime
          meta
          content
          files {
            url
            caption
            id
            name
          }
        }
      }
    }
  }
`
