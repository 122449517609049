import React, { useState } from 'react'
import styled from 'styled-components'

import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'

import { breakpoint, promoFilterItems } from '../../config'
import { formatDate } from '../../utils'

import { Box } from '../box'
import { Title } from '../title'
import { Container } from '../container'
import { Filter } from '../filter'
import { Content } from '../content'
import { Intro } from '../intro'
import { Link } from '../link'

import introImg from '../../assets/chocolate.svg'

const Items = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  margin: 0 -20px;

  @media ${breakpoint.tablet} {
    margin: 0;
  }

  @media ${breakpoint.desktop} {
    margin: -25px;
  }
`

const Item = styled.li`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: auto;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${breakpoint.desktop} {
    padding: 25px;
    margin: 0 !important;
    flex-direction: row;
    width: 50%;
  }
`

const Datetime = styled.div`
  margin-bottom: 1rem;
`

const Text = styled.div`
  margin-bottom: 1rem;
`

const Files = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export function Promos({ promos }) {
  const [showArchived, setShowArchived] = useState(false)

  function handleFilterChange(item) {
    setShowArchived(item.key === 'archive')
  }

  // Derive promos to show (archived/active)
  const promosToShow = promos.filter((promo) => {
    promo.archived = promo.archived || false
    return showArchived === promo.archived
  })

  return (
    <Container>
      <Content
        sidebar={
          <Filter
            label="Kategorien"
            items={promoFilterItems}
            onChange={handleFilterChange}
            defaultActive="current"
          />
        }
      >
        <Intro icon={introImg}>Aktionen</Intro>
        <Items>
          {promosToShow.map((promo) => (
            <Item key={promo.id}>
              <Box>
                <Datetime>
                  {formatDate(promo.datetime)} {promo.meta}
                </Datetime>
                <Title size={2} heading="h2" margin>
                  {promo.title}
                </Title>
                <Text>
                  <ReactMarkdown source={promo.content} plugins={[breaks]} />
                </Text>
                <Files>
                  {promo.files.map((file) => (
                    <li key={file.id}>
                      <Link
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        arrow
                      >
                        {file.caption}
                      </Link>
                    </li>
                  ))}
                </Files>
              </Box>
            </Item>
          ))}
        </Items>
      </Content>
    </Container>
  )
}
